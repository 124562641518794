<template>
  <ecat-table
      ref="table"
      :fields="getFields()"
      :items="loadDepositHistory"
      :pagination-top="true"
      :table="table">
    <template #amount="{ item }">
      <span>{{ item.amount | formatEthBalance }} {{ item.tokenSymbol }}</span>
    </template>

    <template #status="{ item }">
      <span v-if="item.status" class="font-size-18" :class="getStatusColor(item.status)">
        <i class="fa" :class="getStatusIcon(item.status)" />
      </span>
      <span v-else-if="item.type === 'DEPOSIT'" class="font-size-18 text-success">
        <i class="fa fa-check" />
      </span>
    </template>
  </ecat-table>
</template>

<script>
import axios from "axios";
import {dateUtil} from "../../helpers/date-util";

export default {

  data() {
    return {
      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100, 200],
        filter: null,
        filterOn: [],
        sortBy: "id",
        dbSortBy: "",
        sortDesc: false,
        submitted: false,
        inputPage: "",

        items: []
      }
    }
  },

  filters: {
    formatEthBalance(value) {
      if (!value) {
        return '0.00';
      }

      return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 18 }).format(value);
    }
  },

  methods: {
    getFields() {
      return [
        {key: "createdAt", label: this.$t('aderlo-cashback.wallet-history-table.date'), formatter: dateUtil.asDateTimeNormal },
        {key: "type", label: this.$t('aderlo-cashback.wallet-history-table.action'), formatter: value => this.$t(`aderlo-cashback-coin.deposit.type.${value}`)},
        {key: "amount", slot: true, label: this.$t('aderlo-cashback.wallet-history-table.amount') },
        {key: "status", slot: true, label: this.$t('aderlo-cashback.wallet-history-table.status')}
      ]
    },

    async loadDepositHistory() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        let orderBy = {}
        if (this.table.dbSortBy) {
          orderBy = JSON.stringify({
            [this.table.dbSortBy]: this.table.sortDesc ? "DESC" : "ASC"
          })
        }

        const {data} = await axios.get(`/user/cryptocurrency/proof-of-stake/transaction/history/ACC`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            filter: this.table.filter,
            orderBy: orderBy,
          }
        });

        this.table.items = data.transactions
        this.table.rows = data.count
        this.table.totalRows = data.count
      } catch (error) {
        this.table.items = []
      }

      return this.table.items
    },

    getStatusIcon(status) {
      switch (status) {
        case "SUCCESS":
            return 'fa fa-check'
        case "FAILED":
          return "fa fa-times"
        case "PENDING":
          return "fa-clock"
        default:
          return ""
      }
    },

    getStatusColor(status) {
      switch (status) {
        case "SUCCESS":
          return 'text-success'
        case "FAILED":
          return "text-danger"
        case "PENDING":
          return "text-warning"
        default:
          return ""
      }
    },

    refresh() {
      this.$refs.table.refresh()
    }
  }

}
</script>