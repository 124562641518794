<template>
  <b-modal
      v-model="visibleModal"
      title="Wypłata BNB"
      title-class="font-16"
      hide-footer
      centered
      @hide="hideModal"
      @esc="hideModal"
  >
    <div class="row d-flex align-items-center justify-content-between px-2 color-primary mb-3">
      Możliwa ilość BNB do przelania
      <span class="float-right font-weight-bold font-size-14 color-secondary">
        {{ balance | formatEthBalance }} BNB
      </span>
    </div>

    <div class="row px-2 mb-3">
      <label>{{ $t('transfer-tokens-modal.address') }}</label>
      <ecat-input
          v-model="form.toAddress"
          id="externalWallet-input"
          type="text"
          class="col-12 p-0"
          :class="{ 'is-invalid': $v.form.toAddress.$error }"
          @should-reload="estimateGasFee"
      />

      <div v-if="!$v.form.toAddress.required" class="invalid-feedback">{{ $t('message.required') }}</div>
    </div>

    <div class="row d-flex align-items-end px-2 mb-2">
      <ecat-input
          v-model="form.amount"
          :label="$t('message.amount')"
          placeholder="Podaj ilość BNB"
          type="number"
          class="col-10 p-0"
          :class="{ 'is-invalid': $v.form.amount.$error }"
          @should-reload="estimateGasFee"
      />

      <div class="col-2 p-0 pl-2 pb-2">
        <b-button @click="setMax" variant="primary" class="w-100">
          {{ $t('message.set-max') }}
        </b-button>
      </div>

      <div v-if="!$v.form.amount.required" class="invalid-feedback">{{ $t('message.required') }}</div>
    </div>

    <div class="row d-flex align-items-end px-2 mb-2">
      <template v-if="loadingGasFee">
        <p class="text-primary">{{ $t('transfer-tokens-modal.calculating-gas-fee') }}</p>
      </template>
      <template v-else-if="gasFee">
        <p class="text-success">{{ $t('transfer-tokens-modal.gas-fee', { 'gasFee': formatEthBalance(gasFee) }) }}</p>
      </template>
    </div>

    <div class="row px-2">
      <div class="col-8 p-0 pr-2">
        <ecat-button :callback="transfer" variant="primary" class="w-100" message="Przelej BNB" />
      </div>
      <div class="col-4 p-0 pl-2">
        <b-button @click="hideModal" variant="danger" class="w-100">
          {{  $t('message.cancel') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {errorCatcher} from "@/helpers/error-catcher";
import axios from "axios";
import Swal from "sweetalert2";
import BigNumber from "bignumber.js";

export default {
  name: "transfer-tokens-modal",

  props: {
    balance: {
      type: String,
      required: true
    },

    internalWallet: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      visibleModal: false,
      gasFee: null,
      loadingGasFee: false,

      form: {
        toAddress: "",
        amount: "",
        tokenSymbol: "BNB"
      }
    }
  },

  validations: {
    form: {
      toAddress: { required },
      amount: {required},
    }
  },

  filters: {
    formatEthBalance(value) {
      if (!value) {
        return '0.00';
      }

      return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 18 }).format(value);
    }
  },

  methods: {
    formatEthBalance(value) {
      if (!value) {
        return '0.00';
      }

      return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 18 }).format(value);
    },

    openModal() {
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.form.amount = 0
      this.$v.form.$reset()
    },

    async transfer() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      if (new BigNumber(this.form.amount).isZero()) {
        Swal.fire(this.$t("message.error"), "Ilość twoich BNB wynosi zero!", "error")
        return
      }

      if (new BigNumber(this.form.amount).comparedTo(new BigNumber(this.balance)) > 0) {
        Swal.fire(this.$t("message.error"), "Nie posiadasz wystarczająco BNB!", "error")
        return
      }

      const form = Object.assign({}, this.form)
      form.amount = new BigNumber(form.amount).toString()

      try {
        await axios.post(`/user/cryptocurrency/transaction/transfer-bnb`, form, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        Swal.fire(this.$t("message.success"), `Pomyślnie przelano ${this.form.amount} BNB!`, "success");
        this.hideModal()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async estimateGasFee() {
      if (!this.form.toAddress || !this.form.amount) {
        this.loadingGasFee = false
        this.gasFee = null
        return
      }

      if (this.form.amount > this.balance) {
        this.loadingGasFee = false
        this.gasFee = null
        return
      }

      this.loadingGasFee = true

      const form = Object.assign({}, this.form)
      form.fromAddress = this.internalWallet
      form.amount = new BigNumber(form.amount).toString()

      try {
        const { data } = await axios.post(`/user/cryptocurrency/transaction/estimate-gas-fee`, form, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        this.gasFee = data.gasFee
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      this.loadingGasFee = false
    },

    setMax() {
      if (new BigNumber(this.balance).isZero()) {
        this.form.amount = 0
        return
      }

      this.form.amount = this.balance
    }
  }
}
</script>