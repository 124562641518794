<template>
  <b-modal
      v-model="visibleModal"
      :title="$t('deposit-tokens-modal.title')"
      title-class="font-16"
      hide-footer
      centered
      @hide="hideModal"
      @esc="hideModal"
  >
    <div class="row d-flex align-items-center justify-content-between px-2 color-primary mb-3">
      {{ $t('deposit-tokens-modal.available-tokens-to-deposit') }}
      <span class="float-right font-weight-bold font-size-14 color-secondary">
        {{ balance | formatEthBalance }} ACC
      </span>
    </div>

    <div class="row d-flex align-items-end px-2 mb-2">
      <ecat-input
          v-model="form.amount"
          :label="$t('message.amount')"
          :placeholder="$t('withdraw-tokens-modal.type-token-acc-amount')"
          type="number"
          class="col-10 p-0"
          :class="{ 'is-invalid': $v.form.amount.$error }"
      />

      <div class="col-2 p-0 pl-2 pb-2">
        <b-button @click="setMax" variant="primary" class="w-100">
          {{ $t('message.set-max') }}
        </b-button>
      </div>

      <div v-if="!$v.form.amount.required" class="invalid-feedback">{{ $t('message.required') }}</div>
    </div>

    <div class="row px-2">
      <div class="col-8 p-0 pr-2">
        <ecat-button :callback="deposit" variant="primary" class="w-100" :message="$t('deposit-tokens-modal.deposit-tokens')" />
      </div>
      <div class="col-4 p-0 pl-2">
        <b-button @click="hideModal" variant="danger" class="w-100">
          {{  $t('message.cancel') }}
        </b-button>
      </div>
    </div>

  </b-modal>
</template>

<script>
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {required} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import BigNumber from "bignumber.js";

export default {
  name: "withdraw-tokens-modal",

  props: {
    balance: {
      type: String,
      required: true
    },

    internalWallet: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      visibleModal: false,

      form: {
        amount: "",
        tokenSymbol: "ACC"
      }
    }
  },

  validations: {
    form: {
      amount: {required},
    }
  },

  filters: {
    formatEthBalance(value) {
      if (!value) {
        return '0.00';
      }

      return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 18 }).format(value);
    }
  },

  methods: {
    openModal() {
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.form.amount = 0
      this.$v.form.$reset()
    },

    async deposit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      if (new BigNumber(this.form.amount).isZero()) {
        Swal.fire(this.$t("message.error"), this.$t('deposit-tokens-modal.cannot-deposit-this-amount-of-tokens'), "error")
        return
      }

      if (new BigNumber(this.form.amount).comparedTo(new BigNumber(this.balance)) > 0) {
        Swal.fire(this.$t("message.error"), this.$t('deposit-tokens-modal.no-available-this-amount-of-tokens'), "error")
        return
      }

      const form = Object.assign({}, this.form)
      form.amount = new BigNumber(form.amount).toString()

      try {
        await axios.post(`/user/cryptocurrency/proof-of-stake/deposit`, form, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        Swal.fire(this.$t("message.success"), this.$t('deposit-tokens-modal.success', { "amount": form.amount }))
        this.hideModal()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    setMax() {
      if (new BigNumber(this.balance).isZero()) {
        this.form.amount = 0
        return
      }

      this.form.amount = this.balance
    }
  }
}
</script>