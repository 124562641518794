<template>
  <span>
    {{ selectedEan ? selectedEan : eanString() }}
    <span :id="`eans-tooltip-${product.id}`" v-if="ean().length > 1" class="badge badge-soft-success">{{ eanOthersString() }}</span>
     <b-tooltip :target="`eans-tooltip-${product.id}`" placement="right" v-if="ean() && ean().length > 1">
       Inne:
       <br />
       <p class="badge badge-soft-success font-size-12" v-for="(eanString, index) in ean().filter(element => element !== eanString())" :key="index">
         {{ eanString }}
       </p>
     </b-tooltip>
  </span>
</template>

<script>
import {jsonUtil} from "@/helpers/json-util";

export default {

  props: {
    product: {
      type: Object,
      required: true
    },

    selectedEan: {
      type: String
    }
  },

  methods: {
    ean() {
      if (!this.product.ean) {
        return []
      }

      if (Array.isArray(this.product.ean)) {
        return this.product.ean.filter(element => element)
      }

      return jsonUtil.asArray(this.product.ean).filter(element => element)
    },

    eanString() {
      const ean = this.ean(this.product)
      if (ean && ean.length > 0) {
        if (ean[0]) {
          return ean[0].toString()
        }
      }

      return "-"
    },

    eanOthersString() {
      const ean = this.ean(this.product)
      if (ean && ean.length > 1) {
        return `+${ean.length - 1} ${this.$t('message.others')}`
      }
    }
  }

}
</script>